/* eslint-disable react/display-name */
import { get } from "lodash";
import { PublicEnvProvider } from "next-runtime-env";
import RuntimeEnvContainer from "./env-container";

export const getRuntimeEnv = (key: string): any => {
  const isClient = typeof window !== "undefined";
  return isClient ? get(global, `env.${key}`) : get(process.env, key);
};

const withRuntimeEnv =
  (WrappedComponent: any) =>
  ({ children, ...props }: any) => {
    return (
      <PublicEnvProvider>
        <WrappedComponent {...props}>
          {children}
          <RuntimeEnvContainer />
        </WrappedComponent>
      </PublicEnvProvider>
    );
  };

export default withRuntimeEnv;
