"use client";
import { set } from "lodash";
import { useEnvContext } from "next-runtime-env";

const RuntimeEnvContainer = () => {
  const values = useEnvContext();
  set(global, "env", values);
  return null;
};

export default RuntimeEnvContainer;
